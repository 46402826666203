import { VFC } from 'react';

const SearchIcon: VFC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8298 18.8635C18.9055 18.7887 18.9655 18.6996 19.0066 18.6014C19.0476 18.5031 19.0688 18.3977 19.0688 18.2912C19.0688 18.1847 19.0476 18.0793 19.0066 17.981C18.9655 17.8828 18.9054 17.7937 18.8298 17.7189C18.8297 17.7188 18.8297 17.7188 18.8296 17.7187L14.9266 13.8153C16.192 12.2601 16.821 10.2816 16.6844 8.279C16.5457 6.246 15.6287 4.34474 14.1241 2.97073C12.6194 1.59673 10.643 0.855784 8.60603 0.902043C6.56905 0.948302 4.62832 1.7782 3.18759 3.21909C1.74685 4.65999 0.917046 6.60092 0.870792 8.63812C0.824539 10.6753 1.5654 12.6519 2.93925 14.1567C4.3131 15.6615 6.21417 16.5787 8.24695 16.7174C10.2493 16.854 12.2277 16.2249 13.7826 14.9594L17.6861 18.8632L17.6861 18.8633C17.8379 19.0149 18.0436 19.1 18.258 19.1C18.4724 19.1 18.6781 19.0149 18.8298 18.8635ZM18.8298 18.8635C18.8297 18.8635 18.8297 18.8636 18.8296 18.8637L18.7593 18.7925M18.8298 18.8635C18.8299 18.8634 18.8299 18.8633 18.83 18.8633L18.7593 18.7925M18.7593 18.7925C18.8257 18.727 18.8783 18.6489 18.9143 18.5629C18.9502 18.4768 18.9688 18.3845 18.9688 18.2912C18.9688 18.1979 18.9502 18.1056 18.9143 18.0195C18.8783 17.9335 18.8257 17.8554 18.7593 17.7899L17.7568 18.7925C17.8898 18.9254 18.0701 19 18.258 19C18.446 19 18.6263 18.9254 18.7593 18.7925ZM2.51597 8.83207L2.51597 8.83189C2.51276 7.79764 2.76482 6.77858 3.24979 5.86511C3.73476 4.95163 4.43764 4.17197 5.2961 3.59528C6.15456 3.0186 7.14205 2.6627 8.17099 2.55917C9.19993 2.45563 10.2385 2.60766 11.1946 3.00177C12.1508 3.39587 12.9948 4.01987 13.652 4.81842C14.3092 5.61697 14.7592 6.56538 14.9621 7.57954C15.165 8.5937 15.1144 9.64226 14.815 10.6322C14.5155 11.6222 13.9765 12.5229 13.2455 13.2545L13.2454 13.2547C12.3688 14.1353 11.2507 14.736 10.0327 14.9808C8.8146 15.2257 7.55128 15.1036 6.40258 14.6301C5.25389 14.1566 4.27145 13.353 3.5796 12.3209C2.88776 11.2888 2.5176 10.0746 2.51597 8.83207Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

export default SearchIcon;
