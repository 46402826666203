import 'styled-components/macro';
import { FC, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Alert from '@mui/material/Alert';
import isNil from 'lodash/isNil';
import LoadingButton from '@mui/lab/LoadingButton';

import { I18n } from 'src/components/I18n';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';
import {
  ErrorCode,
  getErrorCodeNameBy,
} from 'src/components/shared/FormElements/types';

import { DIVIDER_ENABLE_GUTTER_CLASS_NAME } from '../common/CModal';

import { SocialButtons } from './SocialButtons';

export type ContentFormProps = {
  hideOAuth?: boolean;
  hideHeader: boolean;
  isDesktop: boolean;
  onSubmit: () => void;
  navigateBlock?: ReactNode;
  termsBlock?: ReactNode;
  alertCode?: ErrorCode | null;
  headerI18nId: DefaultAppMessagesTypeKey;
  submitButtonI18nId: DefaultAppMessagesTypeKey;
  loading: boolean;
  subscription?: boolean;
};

export const renderAlert = (alertCode: ErrorCode) => {
  if (alertCode === ErrorCode.NO_ERROR){
    return (
      <Alert severity="success">
        <I18n id="RESET_PASSWORD_FORM.RESULTS.SUCCESS" />
      </Alert>
    );
  }

  return (
    <Alert severity="error">
      <I18n id={getErrorCodeNameBy(alertCode)} />
    </Alert>
  );
};

export const ContentForm: FC<ContentFormProps> = ({
  hideOAuth,
  onSubmit,
  children,
  navigateBlock,
  termsBlock,
  hideHeader,
  isDesktop,
  headerI18nId,
  loading,
  submitButtonI18nId,
  alertCode,
  subscription,
}) => (
  <div
    css={`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: clamp(600px, calc(600px + (100vw - 1200px) / 2), 960px);
      width: 100%;
      margin: auto;
    `}
  >
    <div
      css={`
        display: inherit;
        flex-direction: inherit;
        align-items: inherit;
        max-width: 400px;
        width: inherit;
      `}
    >
      {!hideHeader && (
        <Typography
          variant={isDesktop ? 'h2' : 'h3'}
          marginBottom={(theme) => theme.spacing(10)}
        >
          <I18n id={headerI18nId} />
        </Typography>
      )}
      <Stack spacing={7} width="100%">
        {!isNil(alertCode) && renderAlert(alertCode)}
        <Stack spacing={6} component="form" onSubmit={onSubmit}>
          {children}
          <LoadingButton
            type="submit"
            endIcon={<ArrowForwardIcon />}
            fullWidth
            color="secondary"
            size="large"
            variant="contained"
            loading={loading}
            loadingPosition="end"
          >
            <I18n id={submitButtonI18nId} />
          </LoadingButton>
          {navigateBlock && (
            <Stack spacing={4} alignItems="center">
              {navigateBlock}
            </Stack>
          )}
        </Stack>
        {termsBlock}
      </Stack>
    </div>
  </div>
);
