import { of, merge, iif, Observable } from 'rxjs';
import { ofType, StateObservable } from 'redux-observable';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import _get from 'lodash/get';
import Cookies from 'js-cookie';
import { push } from 'connected-react-router';
import { AxiosError } from 'axios';
import { Action } from 'redux';

import { observableApi$ } from 'src/modules/api';
import {
  login,
  cleanUpUserMetaInfo,
  fetchBookings,
  UnsubscribeUserAction,
  SubscribeUserAction,
  LoginAction,
  logOutAction,
  UpdateUserInfoAction,
  SignUpAction,
  FetchBookingsAction,
  CancelBookingAction,
  ChangeUserPasswordAction,
  ResetUserPasswordAction,
  SendResetPasswordLink,
  UpdateUserConsentsAction,
} from 'src/store/user/actions';
import { ReduxState } from 'src/store/reducers';
import { mapResponse, mapErrorResponse } from 'src/store/epicHelpers';
import {
  AccountLoginRequest,
  AccountLoginResponse,
  AccountRegisterRequest,
  AccountRegisterResponse,
  AccountChangePasswordRequest,
  AccountChangePasswordResponse,
  AccountResetPasswordRequest,
  AccountResetPasswordResponse,
  AccountRequestResetPassword,
  AccountRequestResetPasswordResponse,
} from 'src/models/customers/account';
import {
  CustomersPatchRequest,
  CustomersPatchResponse,
  CustomersBookingResponse,
  CustomerBookingsCancelRequest,
  CustomersGetResponse,
} from 'src/models/customers/manager';
import { ErrorData } from 'src/store/user/reducers';
import {
  FavoriteGetRequest,
  FavoriteGetResponse,
} from 'src/models/customers/favorite';
import {
  CustomerSubscribeRequest,
  CustomerUnsubscribeRequest,
} from 'src/models/customers/subscription';
import { saveCookie } from 'src/modules/helpers';
import { isPageMatched } from 'src/hooks/useMatchPage';

import {
  ACCESS_TOKEN_COOKIE_NAME,
  ID_TOKEN_COOKIE_NAME,
  AccountActionTypes,
  TrackingActionTypes,
} from '../../constants';

const removeCookieBy = (name: string) => Cookies.remove(name);

const CustomersAPI = {
  signIn$: (params: AccountLoginRequest) =>
    observableApi$<AccountLoginResponse>('/customers/login', {
      method: 'post',
      data: params,
    }),
  logout$: () =>
    observableApi$('/customers/logout', {
      method: 'post',
    }),
  signUp$: (params: AccountRegisterRequest) =>
    observableApi$<AccountRegisterResponse>('/customers/register', {
      method: 'post',
      data: params,
    }),
  getUser$: () =>
    observableApi$<CustomersGetResponse>('/customers', {
      method: 'get',
    }),
  updateUser$: (params: Partial<CustomersPatchRequest>) =>
    observableApi$<CustomersPatchResponse>('/customers', {
      method: 'patch',
      data: params,
    }),
  favorites$: (params: FavoriteGetRequest) =>
    observableApi$<FavoriteGetResponse>('/customers/favorites', {
      method: 'get',
      params,
    }),
  bookings$: () =>
    observableApi$<CustomersBookingResponse>('/customers/bookings', {
      method: 'get',
    }),
  cancelBooking$: (params: CustomerBookingsCancelRequest) =>
    observableApi$<CustomersBookingResponse>(
      `/customers/bookings/${params.offerId}/cancel`,
      {
        method: 'post',
        data: params,
      }
    ),
  resetPassword$: (params: AccountResetPasswordRequest) =>
    observableApi$<AccountResetPasswordResponse>('/customers/reset-Password', {
      method: 'post',
      data: params,
    }),
  changePassword$: (params: AccountChangePasswordRequest) =>
    observableApi$<AccountChangePasswordResponse>(
      '/customers/change-Password',
      {
        method: 'post',
        data: params,
      }
    ),
  sendResetPasswordLink$: (params: AccountRequestResetPassword) =>
    observableApi$<AccountRequestResetPasswordResponse>(
      '/customers/request-Reset-Password',
      {
        method: 'post',
        data: params,
      }
    ),
  unsubscribeUser$: (params: CustomerSubscribeRequest) =>
    observableApi$('/customers/unsubscribe', {
      method: 'get',
      params,
    }),
  subscribeUser$: (params: CustomerUnsubscribeRequest) =>
    observableApi$('/customers/subscribe', {
      method: 'get',
      params,
    }),
};

const defaultErrorHandler =
  (type: string, isCleanUp = false) =>
  (err: AxiosError<ErrorData>) => {
    const handleError = mapErrorResponse(type, err);
    const withCleanUp = merge(handleError, of(cleanUpUserMetaInfo()));

    return iif(() => isCleanUp, withCleanUp, handleError);
  };

export function userLogin(
  action$: Observable<LoginAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(AccountActionTypes.USER_LOGIN_REQUEST),
    mergeMap(({ params }) => {
      const es = state$.value.deprecated.es;
      const currentLocation = state$.value.router.location.pathname;
      const isHotelDetailsPage = isPageMatched(
        '/hotel/:hotelId',
        currentLocation
      );

      const signInParams = {
        email: params.email,
        password: params.password,
        ...(isHotelDetailsPage && es ? { es } : {}),
      };

      return CustomersAPI.signIn$(signInParams).pipe(
        mergeMap((response) => {
          // TODO: save id_token too
          saveCookie(ACCESS_TOKEN_COOKIE_NAME, response.token);
          return merge(
            mapResponse(AccountActionTypes.USER_LOGIN_SUCCESS, response),
            of({
              type: TrackingActionTypes.TRACK_LOGIN,
            })
          );
        }),
        catchError(defaultErrorHandler(AccountActionTypes.USER_LOGIN_FAILURE))
      );
    })
  );
}

export function userLogout(action$: Observable<logOutAction>) {
  return action$.pipe(
    ofType(AccountActionTypes.USER_LOGOUT_REQUEST),
    mergeMap(() =>
      CustomersAPI.logout$().pipe(
        mergeMap(() => {
          removeCookieBy(ACCESS_TOKEN_COOKIE_NAME);
          removeCookieBy(ID_TOKEN_COOKIE_NAME);

          return merge(
            of({ type: AccountActionTypes.USER_LOGOUT_SUCCESS }),
            of(push('/'))
          );
        }),
        catchError(defaultErrorHandler(AccountActionTypes.USER_LOGOUT_FAILURE))
      )
    )
  );
}

export function userUpdate(action$: Observable<UpdateUserInfoAction>) {
  return action$.pipe(
    ofType(AccountActionTypes.UPDATE_ACCOUNT_USER_REQUEST),
    mergeMap(({ params }) =>
      CustomersAPI.updateUser$(params).pipe(
        mergeMap((response) =>
          iif(
            () => response.isSuccessful,
            mapResponse(AccountActionTypes.UPDATE_ACCOUNT_USER_SUCCESS, params),
            defaultErrorHandler(AccountActionTypes.UPDATE_ACCOUNT_USER_FAILURE)(
              response as any
            )
          )
        ),
        catchError(
          defaultErrorHandler(AccountActionTypes.UPDATE_ACCOUNT_USER_FAILURE)
        )
      )
    )
  );
}

export function userSignUp(action$: Observable<SignUpAction>) {
  return action$.pipe(
    ofType(AccountActionTypes.USER_SIGN_UP_REQUEST),
    mergeMap(
      ({
        params: {
          email,
          password,
          enableSubscriptions,
          referralCode,
          inviteLink,
        },
      }) => {
        const signUpParams = {
          email,
          password,
          enableSubscriptions,
          inviteLink,
          referralCode,
        };

        return CustomersAPI.signUp$(signUpParams).pipe(
          mergeMap(() =>
            merge(
              of({ type: AccountActionTypes.USER_SIGN_UP_SUCCESS }),
              of({ type: TrackingActionTypes.TRACK_REGISTRATION })
            )
          ),
          catchError(
            defaultErrorHandler(AccountActionTypes.USER_SIGN_UP_FAILURE)
          )
        );
      }
    )
  );
}

export function getBookings(action$: Observable<FetchBookingsAction>) {
  return action$.pipe(
    ofType(AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_REQUEST),
    switchMap(() =>
      CustomersAPI.bookings$().pipe(
        mergeMap((response) =>
          mapResponse(
            AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_SUCCESS,
            response
          )
        ),
        catchError(
          defaultErrorHandler(
            AccountActionTypes.FETCH_ACCOUNT_BOOKINGS_FAILURE,
            true
          )
        )
      )
    )
  );
}

export function cancelBooking(
  action$: Observable<CancelBookingAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(AccountActionTypes.CANCEL_ACCOUNT_BOOKING_REQUEST),
    switchMap(({ params }) => {
      const { user } = state$.value;
      const limit = _get(user, ['bookings', 'limit']);
      const startIdx = _get(user, ['bookings', 'startIndex']);

      return CustomersAPI.cancelBooking$(params).pipe(
        mergeMap((response) =>
          merge(
            mapResponse(
              AccountActionTypes.CANCEL_ACCOUNT_BOOKING_SUCCESS,
              response
            ),
            of(
              fetchBookings({
                startIndex: 0,
                limit: limit + startIdx,
              })
            )
          )
        ),
        catchError(
          defaultErrorHandler(AccountActionTypes.CANCEL_ACCOUNT_BOOKING_FAILURE)
        )
      );
    })
  );
}

export function getUserInfo(
  action$: Observable<Action<AccountActionTypes.FETCH_USER_INFO_REQUEST>>
) {
  return action$.pipe(
    ofType(AccountActionTypes.FETCH_USER_INFO_REQUEST),
    mergeMap(() =>
      CustomersAPI.getUser$().pipe(
        mergeMap((response) =>
          mapResponse(AccountActionTypes.FETCH_USER_INFO_SUCCESS, response)
        ),
        catchError(
          defaultErrorHandler(AccountActionTypes.FETCH_USER_INFO_FAILURE, true)
        )
      )
    )
  );
}

export function changeUserPassword(
  action$: Observable<ChangeUserPasswordAction>
) {
  return action$.pipe(
    ofType(AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_REQUEST),
    mergeMap(({ params }) =>
      CustomersAPI.changePassword$(params).pipe(
        mergeMap((response) =>
          iif(
            () => response.isSuccessful,
            mapResponse(
              AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_SUCCESS,
              response
            ),
            defaultErrorHandler(
              AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_FAILURE
            )(response as any)
          )
        ),
        catchError(
          defaultErrorHandler(
            AccountActionTypes.USER_SETTINGS_CHANGE_PASSWORD_FAILURE
          )
        )
      )
    )
  );
}

export function resetUserPassword(
  action$: Observable<ResetUserPasswordAction>
) {
  return action$.pipe(
    ofType(AccountActionTypes.RESET_ACCOUNT_PASSWORD_REQUEST),
    mergeMap(({ params }) =>
      CustomersAPI.resetPassword$(params).pipe(
        mergeMap((response) =>
          iif(
            () => response.isSuccessful,
            mapResponse(
              AccountActionTypes.RESET_ACCOUNT_PASSWORD_SUCCESS,
              response
            ),
            defaultErrorHandler(
              AccountActionTypes.RESET_ACCOUNT_PASSWORD_FAILURE
            )(response as any)
          )
        ),
        catchError(
          defaultErrorHandler(AccountActionTypes.RESET_ACCOUNT_PASSWORD_FAILURE)
        )
      )
    )
  );
}

export function sendResetPasswordLink(
  action$: Observable<SendResetPasswordLink>
) {
  return action$.pipe(
    ofType(AccountActionTypes.SEND_RESET_PASSWORD_LINK_REQUEST),
    switchMap(({ params }) =>
      CustomersAPI.sendResetPasswordLink$(params).pipe(
        mergeMap((response) =>
          mapResponse(
            AccountActionTypes.SEND_RESET_PASSWORD_LINK_SUCCESS,
            response
          )
        ),
        catchError(
          defaultErrorHandler(
            AccountActionTypes.SEND_RESET_PASSWORD_LINK_FAILURE
          )
        )
      )
    )
  );
}

export function updateUserConsents(
  action$: Observable<UpdateUserConsentsAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_REQUEST),
    mergeMap(({ params }) => {
      const { user } = state$.value;
      const info = _get(user, 'info');
      const oldConsents = _get(info, 'consents');
      const userWithNewConsents = {
        ...info,
        consents: params,
      };

      return CustomersAPI.updateUser$(userWithNewConsents).pipe(
        mergeMap((response) =>
          iif(
            () => response.isSuccessful,
            mapResponse(
              AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_SUCCESS,
              response
            ),
            defaultErrorHandler(
              AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_FAILURE
            )(oldConsents as any)
          )
        ),
        catchError(
          defaultErrorHandler(
            AccountActionTypes.USER_SETTINGS_UPDATE_CONSENTS_FAILURE
          )
        )
      );
    })
  );
}

export function unsubscribeUserByToken(
  action$: Observable<UnsubscribeUserAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(AccountActionTypes.UNSUBSCRIBE_USER_REQUEST),
    switchMap(() => {
      const { token = '' } = state$.value.user.unsubscribe;

      return CustomersAPI.unsubscribeUser$({ token }).pipe(
        mergeMap(() =>
          mapResponse(AccountActionTypes.UNSUBSCRIBE_USER_SUCCESS, {})
        ),
        catchError(
          defaultErrorHandler(AccountActionTypes.UNSUBSCRIBE_USER_FAILURE)
        )
      );
    })
  );
}

export function subscribeUserByToken(
  action$: Observable<SubscribeUserAction>,
  state$: StateObservable<ReduxState>
) {
  return action$.pipe(
    ofType(AccountActionTypes.SUBSCRIBE_USER_REQUEST),
    switchMap(() => {
      const { token = '' } = state$.value.user.unsubscribe;

      return CustomersAPI.subscribeUser$({ token }).pipe(
        mergeMap(() =>
          mapResponse(AccountActionTypes.SUBSCRIBE_USER_SUCCESS, {})
        ),
        catchError(
          defaultErrorHandler(AccountActionTypes.SUBSCRIBE_USER_FAILURE)
        )
      );
    })
  );
}
