import { FC } from 'react';
import { useSelector } from 'react-redux';

import { appConfig } from 'src/modules/app-config';
import { intlSelector } from 'src/store/intl/selectors';

interface TrustScoreWidgetProps {
  hotelTrustYouId: string;
}

export const TrustScoreWidget: FC<TrustScoreWidgetProps> = ({
  hotelTrustYouId,
}) => {
  const { currentLocale } = useSelector(intlSelector);
  const src = `https://api.trustyou.com/hotels/${hotelTrustYouId}/seal.html?size=l&lang=${currentLocale}&key=24dc6ebf-64f7-43c8-b799-b65b7bc46691`;

  return (
    <iframe
      title="hotel’s TrustScore"
      src={src}
      frameBorder="0"
      height="101"
      scrolling="no"
      width="125"
    />
  );
};
