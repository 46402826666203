import noop from 'lodash/noop';
import {
  createContext,
  FC,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components/macro';

import useMatchMedia from 'src/hooks/useMatchMedia';

import CDrawer from '../common/CDrawer';
import CModal from '../common/CModal';
import { I18n } from '../I18n';

import { Auth, EAuth } from '.';

const StyledModal = styled(CModal)`
  border-radius: 0;
  width: 100vw;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  padding: 0;
  display: flex;
  position: relative;
`;

const getHeader = (v: EAuth) => {
  switch (v) {
    case EAuth.SIGN_IN:
      return <I18n id="SIGN_IN_FORM.MODAL.HEADER_NAME" />;
    case EAuth.SIGN_UP:
      return <I18n id="SIGN_UP_FORM.MODAL.HEADER_NAME" />;
    case EAuth.RESET_PASSWORD:
      return <I18n id="RESET_PASSWORD_FORM.MODAL.HEADER" />;
  }
};

const StyledDrawer = styled(CDrawer)`
  width: 100vw;
`;

const AuthModalContext = createContext<(modal: EAuth) => void>(noop);

export const useOpenAuthModal = () => useContext(AuthModalContext);

const OnCloseContext = createContext<MutableRefObject<() => void>>(
  undefined as any
);

export const useSetOnAuthModalClose = (onClose: () => void) => {
  const onCloseRef = useContext(OnCloseContext);

  useEffect(() => {
    onCloseRef.current = onClose;

    return () => {
      onCloseRef.current = noop;
    };
  }, [onClose]);
};

const AuthModalProvider: FC = ({ children }) => {
  const onCloseRef = useRef<() => void>(noop);

  const [modal, setModal] = useState(EAuth.SIGN_IN);

  const [toggler, setToggler] = useState(false);

  const onOpen = useCallback<typeof setModal>((modal) => {
    setModal(modal);

    setToggler(true);
  }, []);

  const handleClose = () => {
    setToggler(false);

    onCloseRef.current();
  };

  const isMobile = useMatchMedia(useTheme().breakpoints.down('md'));

  const content = (
    <Auth
      onSubmit={handleClose}
      activeModal={modal}
      setActiveModal={setModal}
    />
  );

  return (
    <OnCloseContext.Provider value={onCloseRef}>
      <AuthModalContext.Provider value={onOpen}>
        {children}
        {isMobile ? (
          <StyledDrawer
            open={toggler}
            onClose={handleClose}
            header={getHeader(modal)}
          >
            {content}
          </StyledDrawer>
        ) : (
          <StyledModal open={toggler} onClose={handleClose} withCloseButton>
            {content}
          </StyledModal>
        )}
      </AuthModalContext.Provider>
    </OnCloseContext.Provider>
  );
};

export default AuthModalProvider;
