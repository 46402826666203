import { Action } from 'redux';
import { RefObject } from 'react';

import { CheckoutActionTypes } from 'src/constants';
import {
  CheckoutAgreeResponse,
  CheckoutBookResponse,
  CheckoutCheckRequest,
} from 'src/models/checkout';
import {
  StripePaymentSecretRequest,
  TPaymentMethodTypes,
} from 'src/models/payments';
import { ActionSuccess, ActionRequest } from 'src/models/actions';
import { PayAtHotelBookRequest } from 'src/models/payAtHotel';
import { PaymentField } from 'src/components/pages/Checkout/PaymentPanel/CreditCard/common';
import { IPhone } from 'src/components/shared/FormElements/PhoneInput';
import type { DefaultAppMessagesTypeKey } from 'src/containers/ConnectedIntl/messages/defaultMessages';

import { TCardCollect } from '../../components/pages/Checkout/MainForm';

interface SetPackageParams {
  packageId: string;
}

export type SetPackageAction = ActionSuccess<
  CheckoutActionTypes.SET_CHECKOUT_PACKAGE,
  SetPackageParams
>;

export const setPackageAndProceedToCheckout = (
  packageId: string
): SetPackageAction => ({
  type: CheckoutActionTypes.SET_CHECKOUT_PACKAGE,
  payload: {
    packageId,
  },
});

export type CreateCheckoutAction =
  Action<CheckoutActionTypes.CREATE_CHECKOUT_REQUEST>;

export const createCheckout = (): CreateCheckoutAction => ({
  type: CheckoutActionTypes.CREATE_CHECKOUT_REQUEST,
});

export type GetCheckoutInfoAction =
  Action<CheckoutActionTypes.FETCH_CHECKOUT_REQUEST> & { silent?: boolean };

export const getCheckoutInfo = (silent?: boolean): GetCheckoutInfoAction => ({
  type: CheckoutActionTypes.FETCH_CHECKOUT_REQUEST,
  silent,
});

export type CheckCheckoutAction = ActionRequest<
  CheckoutActionTypes.CHECK_CHECKOUT_REQUEST,
  CheckoutCheckRequest
>;

export const checkCheckout = (
  params: CheckoutCheckRequest
): CheckCheckoutAction => ({
  type: CheckoutActionTypes.CHECK_CHECKOUT_REQUEST,
  params,
});

export const stopCheckCheckout =
  (): Action<CheckoutActionTypes.STOP_CHECK_CHECKOUT> => ({
    type: CheckoutActionTypes.STOP_CHECK_CHECKOUT,
  });

interface Name {
  firstName: string;
  lastName: string;
}
interface Country {
  countryCode: string;
}
interface BedType {
  roomBedType: string;
}

export type CheckoutBookFormFields = {
  [P in PaymentField]: string;
} & {
  names: Name[];
  countries: Country[];
  specialRequests: string[];
  bedTypes: BedType[];
  email: string;
  phone: IPhone;
  paymentMethodType?: TPaymentMethodTypes;
};

export interface CheckoutBookActionParams {
  formFields?: CheckoutBookFormFields;
  agreed?: boolean;
  paybyrd?: Omit<Check3DSParams, 'offerKey' | 'cartToken'>;
  crypto?: boolean;
}

export type CheckoutBookAction = ActionRequest<
  CheckoutActionTypes.BOOK_CHECKOUT_REQUEST,
  CheckoutBookActionParams
>;

type Check3DSParams = {
  cardCollect: TCardCollect;
  containerRef: RefObject<HTMLElement | null>;
  setToggler: (value: boolean) => void;
  setBackDropToggler: (value: boolean) => void;
  setError: (textId: DefaultAppMessagesTypeKey) => void;
  offerKey: string;
};

export type Check3DSAction = ActionRequest<
  CheckoutActionTypes.PAYBYRD_3DS_CHECK,
  Check3DSParams
>;

export const triggerPayByrd3DSCheck = (
  params: Check3DSParams
): Check3DSAction => ({
  type: CheckoutActionTypes.PAYBYRD_3DS_CHECK,
  params,
});

export type CheckoutBookSuccessAction = ActionSuccess<
  CheckoutActionTypes.BOOK_CHECKOUT_SUCCESS,
  CheckoutBookResponse
>;

export type UpdateCheckoutPackagePriceAction =
  Action<CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_REQUEST>;

export type UpdateCheckoutPackagePriceSuccessAction = ActionSuccess<
  CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_REQUEST,
  CheckoutAgreeResponse
>;

export const updateCheckoutPackagePrice =
  (): UpdateCheckoutPackagePriceAction => ({
    type: CheckoutActionTypes.UPDATE_CHECKOUT_PACKAGE_PRICE_REQUEST,
  });

export const validateCheckout = (
  params: CheckoutBookActionParams
): CheckoutBookAction => ({
  type: CheckoutActionTypes.BOOK_CHECKOUT_REQUEST,
  params,
});

export type StripePaymentSecretAction = ActionRequest<
  CheckoutActionTypes.STRIPE_PAYMENT_SECRET_REQUEST,
  StripePaymentSecretRequest
>;

export const getStripePaymentSecret = (
  params: StripePaymentSecretRequest
): StripePaymentSecretAction => ({
  type: CheckoutActionTypes.STRIPE_PAYMENT_SECRET_REQUEST,
  params,
});

export type HoldPayAtHotelAction = ActionRequest<
  CheckoutActionTypes.HOLD_PAY_AT_HOTEL_REQUEST,
  StripePaymentSecretRequest
>;

export const holdPayAtHotel = (
  params: StripePaymentSecretRequest
): HoldPayAtHotelAction => ({
  type: CheckoutActionTypes.HOLD_PAY_AT_HOTEL_REQUEST,
  params,
});

export type PayAtHotelBookAction = ActionRequest<
  CheckoutActionTypes.BOOK_PAY_AT_HOTEL_REQUEST,
  PayAtHotelBookRequest
>;

export const payAtHotel = (
  params: PayAtHotelBookRequest
): PayAtHotelBookAction => ({
  type: CheckoutActionTypes.BOOK_PAY_AT_HOTEL_REQUEST,
  params,
});
