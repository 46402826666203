import { FC, memo } from 'react';
import styled from 'styled-components/macro';
import _isNil from 'lodash/isNil';

import { DataElementsId } from 'src/constants';
import TwoColumnLayout, {
  LeftColumn,
  RightColumn,
} from 'src/components/common/TwoColumnLayout';
import { fontSizeBase, fontSizeExtraLarge } from 'src/modules/fontSizes';
import { media } from 'src/modules/mediaQuery';
import { I18n } from 'src/components/I18n';
import { TextDivider } from 'src/components/TextDivider';
import { Phone, TabletDesktop } from 'src/components/shared/CResponsive';
import { getHotelAddress } from 'src/modules/helpers';
import { HotelRate } from 'src/components/common/HotelRate';
import { TrustYou } from 'src/components/HotelItem/TrustYou';
import FavoriteButton from 'src/components/FavoriteButton';
import { HotelPreviewSize } from 'src/components/HotelPreview/type';
import { HotelPreview } from 'src/components/HotelPreview';
import useToggler from 'src/hooks/useToggler';
import { HotelInfoSelectorResult } from 'src/store/hotel/selectors';
import useFormatDistance from 'src/hooks/useFormatDistance';

import { TrustScoreWidget } from './TrustScoreWidget';
import Map from './Map';

const Wrapper = styled.div``;

const StyledHotelPreview = styled(HotelPreview)`
  height: 408px;

  ${media.tablet`
    height: 300px;
  `}
`;

const InfoWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 15px;

  ${media.desktop`
    padding-top: 0;
  `}
`;

const HotelName = styled.h3`
  ${fontSizeExtraLarge};
  line-height: 20px;
  margin: 0 10px 0 0;

  ${media.phone`
    font-size: 20px;
  `}
`;

const StyledTrustYou = styled(TrustYou)`
  margin: 0 0 10px 10px;
`;

const AddressWrapper = styled.div`
  color: ${(p) => p.theme.custom.grayTextColor};
  ${fontSizeBase};
  line-height: 18px;
  margin-top: 5px;
`;

const Address = styled.span``;

const StyledFavoriteButton = styled(FavoriteButton)`
  position: static;
`;

const MapIcon = styled.span.attrs({ className: 'icon-map' })`
  margin-right: 5px;
`;

interface GeneralInfoProps {
  data: NonNullable<HotelInfoSelectorResult>;
  sectionRef?: (el: HTMLDivElement | null) => void;
  hotelTrustYouId?: string;
}

const GeneralInfo: FC<GeneralInfoProps> = memo(
  ({ data, hotelTrustYouId, sectionRef }) => {
    const { distanceToCenter } = data as any;
    const showDistanceToCenter = !_isNil(distanceToCenter);

    const formatDistance = useFormatDistance();

    const { toggler, handleClose, handleOpen } = useToggler();

    return (
      <>
        <Wrapper ref={sectionRef} data-id="overview">
          <TabletDesktop>
            <StyledHotelPreview
              size={HotelPreviewSize.LARGE}
              images={data.images}
              imagesCount={data.imagesCount}
              hotelId={data.id}
              withGallery={true}
              trackingId={DataElementsId.DETAIL_HOTEL_PREVIEW_BUTTON}
              onMapButtonClick={handleOpen}
            />
          </TabletDesktop>
          <Phone>
            <HotelPreview
              size={HotelPreviewSize.MEDIUM}
              images={data.images}
              imagesCount={data.imagesCount}
              withGallery={true}
              hotelId={data.id}
              showMapButton={true}
              onMapButtonClick={handleOpen}
            />
          </Phone>
          <InfoWrapper>
            <TwoColumnLayout>
              <LeftColumn>
                <HotelRate value={data.stars} />
                <StyledTrustYou data={data} />
                <HotelName data-test-id="HOTEL_GENERAL_INFO_NAME">
                  {data.name}
                </HotelName>
                <AddressWrapper>
                  <MapIcon />
                  <Address>{getHotelAddress(data)}</Address>
                  {showDistanceToCenter && (
                    <>
                      <TextDivider />
                      <I18n
                        id="HOTEL_CARD.DISTANCE_TO_CENTER_LABEL"
                        values={{
                          distanceToCenter: formatDistance(distanceToCenter),
                        }}
                      />
                    </>
                  )}
                </AddressWrapper>
              </LeftColumn>
              <RightColumn>
                <Phone>
                  {/* <StyledFavoriteButton hotelId={data.id} /> */}
                </Phone>
                <TabletDesktop>
                  {hotelTrustYouId && (
                    <TrustScoreWidget hotelTrustYouId={hotelTrustYouId} />
                  )}
                </TabletDesktop>
              </RightColumn>
            </TwoColumnLayout>
          </InfoWrapper>
          <Map isOpen={toggler} onClose={handleClose} info={data} />
        </Wrapper>
      </>
    );
  }
);

export default GeneralInfo;
