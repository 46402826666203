import 'styled-components/macro';

import { VFC, MouseEventHandler } from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import { Button } from 'src/components/common/WrappedButtons';
import { I18n } from 'src/components/I18n';
import { ThemeProps } from 'src/types';
import CModal from 'src/components/common/CModal';
import { EAuth } from 'src/components/Auth';
import {
  useOpenAuthModal,
  useSetOnAuthModalClose,
} from 'src/components/Auth/AuthModalProvider';

import { ContentProps } from '..';

export const Modal = styled(CModal)`
  border-radius: 0;
  width: 100vw;
  max-width: 1920px;
  height: 100vh;
  max-height: 1080px;
  padding: 0;
  display: flex;
  position: relative;
`;

export const Content: VFC<ContentProps> = ({ onClose }) => {
  const handleOpenModal = useOpenAuthModal();

  useSetOnAuthModalClose(onClose);

  const openSignIn = () => {
    handleOpenModal(EAuth.SIGN_IN);
  };

  const openSignUp: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    handleOpenModal(EAuth.SIGN_UP);
  };

  return (
    <>
      <Typography variant="h3" marginBottom={2}>
        <I18n id="SIGN_IN_BANNER.TITLE" />
      </Typography>
      <Typography marginBottom={8} variant="body2">
        <I18n id="SIGN_IN_BANNER.SUBTITLE" />
      </Typography>
      <Button
        variant="contained"
        color="buttonSecondary"
        size="large"
        fullWidth
        endIcon={<ArrowForwardIcon />}
        onClick={openSignIn}
        css={`
          margin-bottom: ${({ theme }: ThemeProps) => theme.spacing(5)};
        `}
      >
        <I18n id="HEADER.SIGN_IN_BTN" />
      </Button>
      {/*      <Typography variant="buttonLarge">
        <I18n id="SIGN_IN_FORM.MODAL.FOOTER.TEXT" />{' '}
        <Link
          typography="buttonLarge"
          color="secondary"
          onClick={openSignUp}
          href={EAuth.SIGN_UP}
        >
          <I18n id="SIGN_UP_FORM.NAME" />
        </Link>
      </Typography>*/}
    </>
  );
};
