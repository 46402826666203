import { FC, memo } from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';

import Collapsed from 'src/components/shared/Collapsed';
import { StickyWrapper } from 'src/components/StickyWrapper';
import { useIsPageTopOrShouldCollapse } from 'src/hooks/useIsPageTopOrShouldCollapse';

import {
  HotelDetailsPortal,
  HotelDetailsPortalProps,
} from './HotelDetailsPortal';

const FormContainer = styled.div<{ isPageTop: boolean }>`
  width: 100%;
  background-color: ${(p) =>
    p.isPageTop
      ? p.theme.custom.backgroundMain
      : p.theme.custom.whiteMainColor};
  transition: background-color 0.2s ${(p) => p.theme.custom.defaultEasing};

  ${(p) =>
    !p.isPageTop &&
    css`
      box-shadow: 5px 5px 30px ${rgba(p.theme.custom.blackMainColor, 0.05)};
    `};
`;

export const MobileSearchForm: FC<HotelDetailsPortalProps> = memo((props) => {
  const { isPageTop, isCollapsed } = useIsPageTopOrShouldCollapse();

  return (
    <StickyWrapper>
      <FormContainer isPageTop={isPageTop}>
        <Collapsed collapsed={isCollapsed}>
          <HotelDetailsPortal {...props} />
        </Collapsed>
      </FormContainer>
    </StickyWrapper>
  );
});
