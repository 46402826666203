import styled, { useTheme } from 'styled-components/macro';
import { VFC, useRef, useCallback } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { CPopover } from 'src/components/common/CPopover';
import { Button } from 'src/components/common/WrappedButtons';
import { I18n } from 'src/components/I18n';
import useToggler from 'src/hooks/useToggler';

import { authPopoverProps } from '..';

import { Content } from './Content';
import useMatchMedia from 'src/hooks/useMatchMedia';

const StyledPopper = styled(CPopover)`
  text-align: center;
  width: 420px;
  --padding: ${({ theme }) => theme.spacing(11, 8)};
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;

  & span {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

interface LogoIconProps {
  isContrast?: boolean;
}

export const SignIn: VFC<LogoIconProps> = ({ isContrast = false }) => {
  const { toggler, handleClose, handleOpen } = useToggler();
  const color = isContrast ? '#000000' : '#FFF';
  const theme = useTheme();
  const isMobile = useMatchMedia(theme.breakpoints.down('md'));

  const onClose = useCallback(handleClose, []);
  const Text = styled.div`
  color: ${color};
`;
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={anchorRef}>
      <StyledContainer>
        {!isMobile ? (
            <StyledButton
          color="buttonSecondary"
          onClick={handleOpen}
          className="styled-button"
        >
          <Text>
            <I18n id="HEADER.SIGN_IN_BTN" />
          </Text>
        </StyledButton>
        ) : (
          <StyledButton
          color="buttonSecondary"
          onClick={handleOpen}
          className="styled-button"
          startIcon={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M2 5H22V7H2V5ZM2 11H22V13H2V11ZM22 17H2V19H22V17Z"
                    fill={color} />
            </svg>
          }
        >
        </StyledButton>
        )}
      </StyledContainer>

      <StyledPopper
        {...authPopoverProps}
        open={toggler}
        anchorEl={anchorRef.current}
        onClickOutside={onClose}
      >
        <Content onClose={onClose} />
      </StyledPopper>
    </div>
  );
};
