import { Nullable } from 'src/types';
import { CreditCardTypes } from 'src/constants';
import { CheckoutBookActionParams } from 'src/store/checkout/actions';

import { Currencies } from '../config';

export interface CheckoutCreateRequest {
  searchRequestId: number;
  selectedPackageId: string;
  hotelId: number;
  currency?: string;
  es: string;
}

export interface CheckoutCreateResponse {
  checkoutId: number;
}
export interface Room {
  id: string;
  name: string;
  foodTypeStr: string;
  foodType: number;
  providerRoomRawJson: string;
  alternativeName: string;
  adultsCount: number;
  childrenAges: number[];
  roomContentKey: string;
  bedType: string;
  extraBedIncluded: boolean;
}

export interface TaxAndFee {
  currency: string;
  feeTitle: string;
  frequencyType: string;
  isIncludedInPrice: boolean;
  isMandatory: boolean;
  isValuePercentage: boolean;
  remarks: string;
  unitType: string;
  value: number;
}

export interface CancellationPolicy {
  fee: number;
  dateFrom: string;
  dateTo: string;
  description: string;
  refundability: Refundability;
}

interface InternalPackage {
  packageId: string;
  totalNights: number;
  refundability: number;
  rooms: Room[];
  salePrice: number;
  packageGroupingCategory: string;
  score: number;
  creditCardFee: number;
  taxAndFees: TaxAndFee[];
  cancellationPolicies: Nullable<CancellationPolicy>;
  packageGroupingHash: number;
  roomCategoryHash: string;
  refundableUntil: string;
  creditCardType: CreditCardTypes;
  traderInformation: Nullable<TraderInformation>;
}

interface Url {
  hotelKeyStr: string;
  price: number;
  competitorPrice: number;
  tax: number;
  fee: number;
  sid: number;
  requestedSystem: number;
  expireAtUtc: string;
  correlationId: string;
  marketingLink: string;
}

interface CardFee {
  include: boolean;
  percent: number;
}

interface Customer {
  id: number;
  deviceType: number;
  isLoggedIn: boolean;
  residency: string;
  ipAddress: string;
  cardFee: CardFee;
  domainName: string;
}

export interface CheckoutRequest {
  checkoutId: number;
}

export interface CheckoutResponse {
  sid: number;
  hotelKeyStr: string; // "hotelId:checkIn:checkOut:roomsParams:country"
  expiration: string;
  currency: string;
  customerId: number;
  hotelId: number;
  isGuaranteed: boolean;
  internalPackage: InternalPackage;
  hotelCode: string;
  listUrl: Url[];
  customer: Customer;
  cardFee: CardFee;
  hotelInfoSlim: string;
  ipCountryCode: string;
  currencies: Currencies;
}

export interface CheckoutCheckRequest {
  customerEmail?: string;
  customerNationality?: Nullable<string>;
  bookAfterModal?: CheckoutBookActionParams;
}

export enum Refundability {
  REFUNDABLE = 1,
  NON_REFUNDABLE = 2,
}

export enum CheckoutStatuses {
  Pending,
  Available,
  SoldOutRoom,
  SoldOutHotel,
  Expired,
  ExpiredNi,
}

interface Address {
  city: Nullable<string>;
  countryCode: Nullable<string>;
  line1: Nullable<string>;
  line2: Nullable<string>;
  line3: Nullable<string>;
  postalCode: Nullable<string>;
  stateProvinceCode: Nullable<string>;
}

interface Trader {
  address: Nullable<Address>;
  termsAndConditions: Nullable<string>;
  contactMessage: Nullable<string>;
  name: Nullable<string>;
}
export interface TraderInformation {
  termsAndConditions: Nullable<string>;
  trader: Nullable<Trader>;
}

export interface CheckoutCheckResponse {
  checkoutId: number;
  cancellation: Nullable<CancellationPolicy>;
  isFinished: boolean;
  checkoutStatus: CheckoutStatuses;
  validPrice: number;
  creditCardFee: number;
  providerType: number;
  taxAndFees: TaxAndFee[];
  refundabilityChanged: boolean;
  traderInformation: Nullable<TraderInformation>;
}

export interface CheckoutAgreeRequest {
  checkoutId: number;
}

export interface CheckoutAgreeResponse {
  checkoutId: number;
  salePrice: number;
  creditCardFee: number;
  taxAndFees: TaxAndFee[];
}

interface AdditionalGuestsInfo {
  firstName: string;
  lastName: string;
  bedTypes: number;
}

interface CustomerInfo {
  firstName: string;
  lastName: string;
  nationality: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  specialRequests: string;
  bedTypes: number;
  additionalGuestsInfo: AdditionalGuestsInfo[];
}

export interface CheckoutBookRequest {
  agreed: boolean;
  checkoutId: number;
  currency: string;
  locale: string;
  customerInfo: CustomerInfo;
}

export interface CheckoutBookResponse {
  offerKey: Nullable<string>;
}
