import { Modify, Nullable } from 'src/types';
import { RoomFacility } from 'src/models/config';
import { HotelReviewScoreCategory } from 'src/hooks/swr/useConfig';

import { CheckoutStatuses, TaxAndFee } from '../checkout';

export interface GeoInfo {
  lat: number;
  lng: number;
}

export interface ReviewInfo {
  id: string;
  name: string;
  score: number;
  category: HotelReviewScoreCategory;
  reviewsCount: number;
}

export interface Image {
  id: string;
  url: string;
}
type RawImage = string;

export interface HotelPolicy {
  data: {
    infantAge: number;
    childrenAgeFrom: number;
    childrenAgeTo: number;
    childrenStayFree: true;
    minGuestAge: number;
  };
  providerId: number;
  source: number;
}

export interface HotelInfo {
  distanceToPoint: number;
  id: number;
  name: string;
  stars: number;
  country: string;
  countryCode: string;
  city: string;
  address: string;
  isFavorite: boolean;
  geo: GeoInfo;
  review: ReviewInfo | null;
  hotelFacilities: number[];
  price: number;
  marketPrice: number;
  pricePerNight: number;
  tax: number;
  fee: number;
  description: string;
  images: Image[];
  email: string;
  zipCode: string;
  url: string;
  suitableTypes: number[];
  es: string;
  tradable: boolean;
  trustYouScore: number;
  imagesCount: number;
  hotelPolicies: HotelPolicy[];
}

interface PriceWithProviderAndCode {
  packageId: string;
  tax: number;
  fee: number;
  price: number;
  pricePerNight: number;
}

export interface RoomGroup {
  id: string;
  boarding: number;
  isRefundable: boolean;
  price: PriceWithProviderAndCode;
  isCheapest: boolean;
}

export interface Room {
  id: string;
  name: string;
  bedType: string;
  facilities: RoomFacility[];
  groups: RoomGroup[];
  images: Image[];
}

export interface HotelRoomsRequest {
  hotelId: number;
  searchId: number;
  es?: string;
}

export enum AffiliateApiSystem {
  None = 0,
  Google = 1,
  Kayak = 2,
  Trivago = 3,
  Bing = 4,
  TripAdvisor = 5,
  Facebook = 6,
  Wego = 7,
  TripAdvisorBackup = 10, // 0x0000000A
  Mock = 100, // 0x00000064
}

interface HotelDetails {
  rooms: Room[];
  guaranteedOfferRoom: Nullable<{
    id: string;
    name: string;
    facilities: RoomFacility[];
    images: Image[];
    boarding: number;
    isRefundable: boolean;
    tax: number;
    fee: number;
    price: number;
    pricePerNight: number;
    priceChangePercent: Nullable<number>;
    packageId: string;
    taxesAndFees: TaxAndFee;
    status: CheckoutStatuses;
    source: AffiliateApiSystem;
  }>;
}

export enum CtDomainMarketingPricePresentation {
  IncludedTaxes = 0,
  AllInclusive = 1,
  BaseRate = 2,
}

export interface HotelRoomsResponse {
  hotelDetails: HotelDetails;
  totalNights: number;
  isFinished: boolean;
  hasImages: boolean;
  pricePresentation: CtDomainMarketingPricePresentation;
}

export interface HotelRequest {
  hotelId: number;
  searchId?: number;
  locale: string;
}

export type HotelResponse = Modify<
  HotelInfo,
  {
    images: RawImage[];
  }
>;

export interface HotelImagesRequest {
  hotelId: number;
}

export type HotelImagesResponse = Nullable<RawImage[]>;
