import { MouseEvent, VFC } from 'react';
import styled from 'styled-components/macro';

import { media } from 'src/modules/mediaQuery';

const Button = styled.button`
  position: absolute;
  top: calc(50% - 55px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  font-size: 10px;
  color: ${(p) => p.theme.custom.reverseTextColor};
  background-color: ${(p) => p.theme.custom.greenMainColor};
  box-shadow: none;
  outline: none;
  transition: opacity 0.35 ${(p) => p.theme.custom.defaultEasing};
  z-index: 1;
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(p) => p.theme.custom.reverseTextColor};
    background-color: ${(p) => p.theme.custom.greenMainColor};
  }

  &:hover {
    opacity: 0.8;
  }

  .icon-arrow::before {
    position: relative;
    display: inline-block;
  }

  ${media.phone`
    position: fixed;
    bottom: 15vh;
    top: initial;
  `}
`;

const ButtonPrev = styled(Button)`
  left: 0;

  .icon-arrow::before {
    transform: rotate(270deg);
  }

  ${media.phone`
    left: 15px;
  `}

  ${media.tablet`
    left: 30px;
  `}
`;

const ButtonNext = styled(Button)`
  right: 0;

  .icon-arrow::before {
    transform: rotate(90deg);
  }

  ${media.phone`
    right: 15px;
  `}

  ${media.tablet`
    right: 30px;
  `}
`;

interface NavigationProps {
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

export const NavigationPrev: VFC<NavigationProps> = ({ onClick }) => (
  <ButtonPrev onClick={onClick}>
    <span className="icon-arrow" />
  </ButtonPrev>
);

export const NavigationNext: VFC<NavigationProps> = ({ onClick }) => (
  <ButtonNext onClick={onClick}>
    <span className="icon-arrow" />
  </ButtonNext>
);
