import 'styled-components/macro';

import {
  VFC,
  useRef,
  ComponentType,
  useEffect,
  useContext,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import Divider from '@mui/material/Divider';
import useTheme from '@mui/system/useTheme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { stringifyUrl } from 'query-string';
import { push } from 'connected-react-router';
import { useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import Stack from '@mui/material/Stack';
import cloneDeep from 'lodash/cloneDeep';

import { I18n } from 'src/components/I18n';
import { Button } from 'src/components/common/WrappedButtons';
import { modalMixin } from 'src/components/common/CModal';
import joinBy from 'src/modules/joinBy';
import SearchIcon from 'src/icons/Search';
import useMatchMedia from 'src/hooks/useMatchMedia';
import { SearchParamsState } from 'src/store/search/reducers';
import { ResetFiltersContext } from 'src/components/pages/Search/SearchPageProvider';
import { setSearchParams } from 'src/store/search/actions';
import { hotelSelector } from 'src/store/hotel/selectors';
import { store } from 'src/store';
import { STATIC_FORM_VALUES } from 'src/components/pages/HotelDetails';
import { stringifyClientOptions } from 'src/modules/queryStringOptions';
import {
  datesConverter,
  roomsConverter,
} from 'src/components/pages/Search/utils';
import { setDeprecatedParams } from 'src/store/deprecated/actions';

import { RoomChooser } from './choosers/RoomChooser';
import { DatesChooser } from './choosers/DatesChooser';
import { DestinationChooser } from './choosers/DestinationChooser';
import './index.css';

export enum FieldsName {
  DESTINATION = 'destination',
  DATES = 'dates',
  ROOMS = 'rooms',
  BUSINESS_TRIP = 'businessTrip',
}

const Z_INDEX = 6;

const Container = styled.div`
  ${modalMixin}
  position: relative;
  z-index: ${Z_INDEX};
  min-width: 90%;
`;

const StyledDividerContainer = styled.div`
    align-self: stretch !important;
`;

const StyledButton = styled(Button)`
    background: #006ce4 !important;
    width: 164px !important;
    padding: 16px !important;
    
    &:hover {
        background: #004c9f !important;
    }

    &:active {
        background: #003a7a !important;
    }
`;


export type SearchPanelInputProps = {
  isMobile: boolean;
  handleIsOpen?: (isOpen: boolean) => void;
  openDefault: boolean;
};

const StylesButtonText = styled.span``;

const choosers: {
  Component: ComponentType<SearchPanelInputProps>;
  name: FieldsName;
}[] = [
  {
    Component: styled(DestinationChooser)`
      flex-grow: 1;
    `,
    name: FieldsName.DESTINATION,
  },
  { Component: DatesChooser, name: FieldsName.DATES },
  { Component: RoomChooser, name: FieldsName.ROOMS },
];

export type SearchPanelProps = {
  isSearchPage?: boolean;
  defaultInput?: FieldsName;
  defaultValues: SearchParamsState;
  onSubmit?: () => void;
} & Pick<SearchPanelInputProps, 'handleIsOpen'>;

const validationSchema = yup.object().shape({
  [FieldsName.DESTINATION]: yup.object().required(),
  [FieldsName.BUSINESS_TRIP]: yup.boolean(),
  [FieldsName.ROOMS]: yup.array().required(),
  [FieldsName.DATES]: yup
    .array()
    .of(yup.date().required())
    .length(2)
    .required(),
});

export const getSearchUrl = (state: SearchParamsState) =>
  stringifyUrl(
    {
      url: '/search',
      query: {
        ...state[FieldsName.DESTINATION],
        [FieldsName.DATES]: datesConverter.encode(
          state[FieldsName.DATES] as any
        ),
        [FieldsName.ROOMS]: roomsConverter.encode(state[FieldsName.ROOMS]),
        [FieldsName.BUSINESS_TRIP]: state[FieldsName.BUSINESS_TRIP],
      },
    },
    stringifyClientOptions
  );

export type SearchQueryParams = SearchParamsState[FieldsName.DESTINATION] &
  Omit<SearchParamsState, FieldsName.DESTINATION>;

export const SearchPanel = forwardRef<HTMLDivElement, SearchPanelProps>(
  (
    { isSearchPage, defaultInput, handleIsOpen, defaultValues, onSubmit },
    outerRef
  ) => {
    const { breakpoints } = useTheme();

    const dispatch = useDispatch();

    const isDesktop = useMatchMedia(breakpoints.up('lg'));

    const isMobile = useMatchMedia(breakpoints.down('sm'));

    const { listen } = useHistory();

    const methods = useForm({
      defaultValues,
      resolver: yupResolver(validationSchema),
    });

    const defaultValuesRef = useRef(defaultValues);

    defaultValuesRef.current = defaultValues;

    const { handleSubmit, reset, watch } = methods;

    useEffect(() => {
      if (!isSearchPage) {
        const t = watch(() => {
          void handleSubmit((data) => {
            dispatch(setSearchParams(cloneDeep(data)));
          })();
        });

        return () => t.unsubscribe();
      }
    }, [watch, isSearchPage]);

    useEffect(
      () =>
        listen((_, action) => {
          if (action === 'POP') {
            reset(defaultValuesRef.current);
          }
        }),
      []
    );

    const dividedInputs = choosers.map(({ Component, name }) => (
      <Component
        key={name}
        isMobile={isMobile}
        openDefault={name === defaultInput}
        handleIsOpen={handleIsOpen}
      />
    ));

    const restInputs: JSX.Element[] = [];

    const disableSubmit = isSearchPage && !methods.formState.isDirty;

    const ctx = useContext(ResetFiltersContext);

    const submitButton = (
      <div key="submitButton">
        <StyledButton
          className="search-button"
          key="submitButton"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={handleSubmit((data) => {
            reset(data);

            dispatch(setSearchParams(data));

            dispatch(push(getSearchUrl(data)));

            ctx.resetFilters?.(false);

            onSubmit?.();
          })}
          sx={isDesktop ? { borderRadius: '16px' } : undefined}
        >
          <StylesButtonText className="search-button-text">
            Search
          </StylesButtonText>
        </StyledButton>
      </div>
    );

    (isDesktop ? restInputs : dividedInputs).push(submitButton);

    return (
      <FormProvider {...methods}>
        {isDesktop ? (
          <Stack
            direction={{ xs: 'column', lg: 'row' }}
            justifyContent={{ lg: 'space-between' }}
            spacing={{ xs: 8, lg: '3%' }}
          >
            {joinBy(
              dividedInputs,
              <StyledDividerContainer>
                <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} />
              </StyledDividerContainer>
            )}
            {restInputs}
          </Stack>
        ) : (
          <Container ref={outerRef}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent={{ lg: 'space-between' }}
              spacing={{ xs: 8, lg: '3%' }}
            >
              {joinBy(
                dividedInputs,
                <StyledDividerContainer>
                  <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} />
                </StyledDividerContainer>
              )}
              {restInputs}
            </Stack>
          </Container>
        )}
      </FormProvider>
    );
  }
);

// this is temporary solution
export const HotelSearchPanel: VFC<
  Pick<SearchPanelProps, 'onSubmit'> & { isStatic: boolean }
> = ({ onSubmit, isStatic }) => {
  const { breakpoints } = useTheme();

  const dispatch = useDispatch();

  const isDesktop = useMatchMedia(breakpoints.up('lg'));

  const isMobile = useMatchMedia(breakpoints.down('sm'));

  const hotel = useSelector(hotelSelector);

  const methods = useForm({
    defaultValues: {
      ...(isStatic ? STATIC_FORM_VALUES : store.getState().searchParams),
      [FieldsName.DESTINATION]: {
        q: hotel.info?.name || '',
      },
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, reset } = methods;

  const dividedInputs = choosers.map(({ Component }, index) => (
    <Component isMobile={isMobile} openDefault={false} key={index} />
  ));

  const restInputs: JSX.Element[] = [];

  const disableSubmit = !methods.formState.isDirty;

  const submitButton = (
    <div key="submitButton">
      <StyledButton
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handleSubmit((data) => {
          reset(data);

          if (data.destination.q !== hotel.info?.name) {
            dispatch(setSearchParams(data as any));

            dispatch(push(getSearchUrl(data as any)));

            dispatch(
              setDeprecatedParams({
                es: undefined,
                searchId: undefined,
                Residency: undefined,
              })
            );
          } else {
            dispatch(setSearchParams(omit(data, FieldsName.DESTINATION)));

            onSubmit?.();
          }
        })}
        endIcon={!isDesktop && <ArrowForwardIcon />}
        disabled={disableSubmit}
        sx={isDesktop ? { borderRadius: '16px' } : undefined}
      >
        {isDesktop ? (
          <SearchIcon />
        ) : (
          <I18n id="HOME_PAGE.SEARCH_PANEL.SEARCH_BTN" />
        )}
      </StyledButton>
    </div>
  );

  (isDesktop ? restInputs : dividedInputs).push(submitButton);

  return (
    <FormProvider {...methods}>
      <Container>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
          spacing={{ xs: 8, lg: '3%' }}
        >
          {joinBy(
            dividedInputs,
            <StyledDividerContainer>
              <Divider orientation={isDesktop ? 'vertical' : 'horizontal'} />
            </StyledDividerContainer>
          )}
          {restInputs}
        </Stack>
      </Container>
    </FormProvider>
  );
};
