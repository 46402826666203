import { VFC } from 'react';
import styled from 'styled-components/macro';

const Rate = styled.div`
  display: inline-block;
  vertical-align: baseline;
  font-size: 11px;
  line-height: 1;
`;

const StarIcon = styled.span.attrs({ className: 'icon-star' })`
  margin-right: 2px;
  color: ${(p) => p.theme.custom.primaryColor};
`;

interface RateProps {
  className?: string;
  value: number;
}

export const CRate: VFC<RateProps> = ({ className, value }) => (
  <Rate className={className}>
    {Array.from(new Array(value), (_, index) => (
      <StarIcon key={index} />
    ))}
  </Rate>
);
