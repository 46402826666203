import { useRef, VFC } from 'react';

import { CPopover } from 'src/components/common/CPopover';
import useToggler from 'src/hooks/useToggler';

import { authPopoverProps } from '..';

import { AccountButton } from './AccountButton';
import { Content } from './Content';

interface LogoIconProps {
  isContrast?: boolean;
}

export const Account: VFC<LogoIconProps> = ({ isContrast = false }) => {
  const { toggler, handleClose, handleOpen } = useToggler();

  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={anchorRef}>
      <AccountButton onClick={handleOpen} isContrast={isContrast} />
      <CPopover
        {...authPopoverProps}
        open={toggler}
        anchorEl={anchorRef.current}
        onClickOutside={handleClose}
      >
        <Content onClose={handleClose} />
      </CPopover>
    </div>
  );
};
