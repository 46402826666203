import {
  CancellationPolicy,
  TaxAndFee,
  TraderInformation,
} from 'src/models/checkout';
import { GeoInfo, ReviewInfo, Image } from 'src/models/hotels';
import { Nullable } from 'src/types';
import { CreditCardTypes } from 'src/constants';

import { CustomerDetails, CustomerResponse } from '../common';

export type CustomersGetResponse = CustomerDetails;

export type CustomersPatchRequest = CustomerDetails;
export type CustomersPatchResponse = CustomerResponse;

export interface CustomerRoomInfo {
  roomId: string;
  specialRequest: Nullable<string>;
  name: string;
  surname: string;
  bedType: number;
  childrenAges: number[];
  adults: number;
  foodType: number;
  roomName: string;
}

interface PlaceNearby {
  category: string;
  name: string;
  geo: GeoInfo;
}

interface Content {
  description: string;
  placesNearby: PlaceNearby[];
}

export interface CustomerHotelInfo {
  id: number;
  name: string;
  stars: number;
  country: string;
  countryCode: string;
  city: string;
  address: string;
  isFavorite: boolean;
  geo: GeoInfo;
  reviews: ReviewInfo[];
  facilities: number[];
  description: Nullable<string>;
  content: Nullable<Content>;
  images: Image[];
  distanceToCenter: number;
  email: string;
  zipCode: string;
  url: string;
  suitableTypes: string[];
}

export enum BookingStatus {
  Completed = 'Completed',
  Canceled = 'Canceled',
  Processing = 'Processing',
}

export interface Reservation {
  id: string;
  hotelId: number;
  offerId: number;
  bookingNumber: string;
  customerRoomsInfo: CustomerRoomInfo[];
  customerPhoneNumber: Nullable<string>;
  customerEmail: Nullable<string>;
  nights: number;
  checkIn: string;
  checkOut: string;
  cancellationPolicies: CancellationPolicy[];
  hotelInfo: CustomerHotelInfo;
  currency: string;
  status: BookingStatus;
  exchangeRate: number;
  creditCardType: CreditCardTypes;
  salePrice: number;
  creditCardFee: number;
}

export interface CustomersBookingResponse {
  totalCount: number;
  items: Reservation[];
}

interface Room {
  roomId: string;
  name: string;
  foodType: number;
  adultsCount: number;
  childrenAges: number[];
  hasExtraBed: boolean;
}

interface RoomPackage {
  packageId: string;
  totalNights: number;
  refundability: number;
  rooms: Room[];
  salePrice: number;
  creditCardFee: number;
  taxAndFees: TaxAndFee[];
  cancellationPolicies: CancellationPolicy[];
  traderInformation: Nullable<TraderInformation>;
}

interface CustomerInfo {
  customerRooms: CustomerRoomInfo[];
}

export interface CustomerOfferRequest {
  offerKey: string;
}

export interface CustomerOfferResponse {
  package: Nullable<RoomPackage>;
  customerInfo: Nullable<CustomerInfo>;
  hotelId: Nullable<number>;
  checkInDate: string;
  checkOutDate: string;
  currency: Nullable<string>;
  exchangeRate: number;
  customerId: Nullable<number>;
  customerPhoneNumber: Nullable<string>;
  customerEmail: Nullable<string>;
  isSuccess: boolean;
  isFinished: boolean;
  status: Nullable<BookingStatus>;
  creditCardType: CreditCardTypes;
}

export interface CustomerBookingsCancelRequest {
  offerId: string;
}

export type CustomerBookingsCancelResponse = CustomerResponse;
