import { FC } from 'react';
import styled from 'styled-components/macro';

import {
  FoodType,
  getFoodTypeNameBy,
  getRefundabilityLabel,
} from 'src/modules/getRoomInfo';
import { I18n } from 'src/components/I18n';
import { PriceDisplay } from 'src/components/PriceDisplay';
import { CtDomainMarketingPricePresentation } from 'src/models/hotels';

const FoodTypeName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(p) => p.theme.custom.blackMainColor};
`;

type RefundabilityTypeProps = {
  isRefundable?: boolean;
};

const RefundabilityType = styled.div<RefundabilityTypeProps>`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: ${(p) =>
    p.isRefundable
      ? p.theme.custom.greenSecondaryColor
      : p.theme.custom.primaryColor};
`;

const TaxesChargesAndFees = styled(RefundabilityType)`
  color: ${(p) => p.theme.custom.blackMainColor};
`;

const TaxesText = styled.div`
  font-size: 11px;
  line-height: 17px;
  color: ${(p) => p.theme.custom.grayTextColor};
`;

interface PackageInfoProps {
  foodType: FoodType;
  isRefundable: boolean;
  tax: number;
  fee: number;
  pricePresentation: CtDomainMarketingPricePresentation;
  className?: string;
}

export const RoomInfo: FC<PackageInfoProps> = ({
  className,
  foodType,
  isRefundable,
  tax,
  fee,
  pricePresentation,
}) => {
  const foodTypeNameIntlId = getFoodTypeNameBy(foodType);
  const taxI18nId =
    pricePresentation === CtDomainMarketingPricePresentation.BaseRate
      ? 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.EXCLUDE_FEES'
      : 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.INCLUDE_FEES';
  const taxI18nValues = {
    amount: <PriceDisplay valuesInEur={tax} />,
  };

  const extraChargesI18nId =
    pricePresentation === CtDomainMarketingPricePresentation.AllInclusive
      ? 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.INCLUDE_EXTRA_CHARGES'
      : 'HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.EXCLUDE_EXTRA_CHARGES';
  const extraChargesI18nValues = {
    amount: <PriceDisplay valuesInEur={fee} />,
  };

  return (
    <div className={className}>
      <FoodTypeName>
        <I18n id={foodTypeNameIntlId} />
      </FoodTypeName>
      <RefundabilityType isRefundable={isRefundable}>
        <I18n id={getRefundabilityLabel(isRefundable)} />
      </RefundabilityType>
      {(Boolean(fee) || Boolean(tax)) && (
        <TaxesChargesAndFees>
          <I18n id="HOTEL_DETAILS_PAGE.ROOMS.PACKAGES.TAX_CHARGES_AND_FEES" />
        </TaxesChargesAndFees>
      )}
      {Boolean(tax) && (
        <TaxesText data-test-id="HOTEL_DETAILS_PAGE.ROOMS.TAX_TEXT">
          <I18n id={taxI18nId} values={taxI18nValues} />
        </TaxesText>
      )}
      {Boolean(fee) && (
        <TaxesText data-test-id="HOTEL_DETAILS_PAGE.ROOMS.FEE_TEXT">
          <I18n id={extraChargesI18nId} values={extraChargesI18nValues} />
        </TaxesText>
      )}
    </div>
  );
};
